<template>
    <BCard class="border border-0 comunityCard">
        <img class="comunityCard__img" :src="`${this.baseUrl}${data.imageFile}`" alt="" />
        <BCardTitle class="font-weight-bold comunityCard__title mt-3 fs-3">
            <u>{{ data?.name }}</u>
        </BCardTitle>
        <BCardText class="comunityCard__description" v-html="formattedText">
        </BCardText>
    </BCard>
</template>
<script>
    import "../assets/scss/index.scss";

    export default {
        props: {
            data: Object,
        },
        computed: {
            formattedText() {
                return this.data?.extra?.replace(/\n/g, "<br>");
            },
        },
    };
</script>
<style lang="scss"></style>
