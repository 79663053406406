<template>
    <div class="news">
        <h2 class="text-center text-md-start mb-3">News</h2>
        <BRow class="m-0 p-0" v-if="!newsCardsData?.length">
            <BCol lg="12" class="news__block">
                <BRow>
                    <BCol cols="12" class="p-2 p-lg-3 bg-white rounded w-full px-3">
                        There's no news to display right now!
                    </BCol>
                </BRow>
            </BCol>
        </BRow>
        <BRow class="m-0 p-0">
            <BCol lg="8" md="6" class="news__block">
                <BRow>
                    <BCol cols="6" sm="12" md="12" lg="6" xl="4" class="p-1 p-lg-2" v-for="(newsCard, index) in newsCardsData" :key="index"
                        @click="handleSelectedNews(newsCard)">
                        <NewsCards class="mb-3" :newsBtnText="newsCard.extra" :newsImage="`${this.baseUrl}${newsCard.imageFile}`" :newsText="newsCard.name" />
                    </BCol>
                </BRow>
            </BCol>
            <BCol lg="4" md="6" class="mx-0 mt-1 d-none d-md-block" v-if="selectedNews !== null">
                <CommunityCard :data="selectedNews" class="fixed-comunity-card" />
            </BCol>

            <b-modal v-model="openModal" size="lg" hide-header hide-footer class="winnerAward-modal d-md-none" :centered="true">
                <img src="../assets/svgs/close.svg" class="close__img" @click="openModal = false" />
                <BCard class="border border-0 comunityCard h-auto">
                    <div>
                        <div>
                            <img class="mb-2 comunityCard__img" :src="`${this.baseUrl}${selectedNews?.imageFile}`" alt="" />
                            <div class="d-flex align-items-center mb-2" v-if="$route.name == 'sponsors'">
                                <img class="comunityCard__logo" :src="`${this.baseUrl}${selectedNews.imageFile}`" />
                                <p class="mb-0 sponsor__card__imgBox-text">
                                    {{ selectedNews?.name }}
                                </p>
                            </div>
                        </div>
                        <BCardTitle class="font-weight-bold comunityCard__title">{{ selectedNews?.name }}</BCardTitle>
                        <BCardText class="comunityCard__description">
                            {{ selectedNews?.extra }}
                        </BCardText>
                    </div>
                </BCard>
            </b-modal>
        </BRow>
    </div>
</template>

<script>
    import NewsCards from "@/components/NewsCards.vue";
    import CommunityCard from "@/components/CommunityCard.vue";

    export default {
        components: {
            NewsCards,
            CommunityCard,
        },
        data() {
            return {
                newsCardsData: [],
                selectedNews: null,
                openModal: false,
            };
        },
        watch: {
            golfClubData() {
                if (
                    this.golfClubData &&
                    this.golfClubData.news &&
                    this.golfClubData.news.length > 0
                ) {
                    this.selectedNews = this.golfClubData.news[0];
                    this.newsCardsData = this.golfClubData.news;
                } else {
                    this.selectedNews = null;
                }
            },
        },
        methods: {
            handleSelectedNews(newCard) {
                this.selectedNews = newCard;
                this.openModal = true;
            },
        },

        mounted() {
            if (
                this.golfClubData &&
                this.golfClubData.news &&
                this.golfClubData.news.length > 0
            ) {
                this.newsCardsData = this.golfClubData.news;
                this.selectedNews = this.golfClubData?.news[0];
            }
        },
    };
</script>

<style lang="scss">
.fixed-comunity-card {
    position: fixed !important;
    width: 400px !important;

    @media screen and (max-width: 1440px) {
        width: 340px !important;
        height: auto !important;
    }

    @media screen and (min-width: 2560px) and (max-width: 5000px) {
        width: 25% !important;
        height: auto !important;
    }
}
</style>
