<template>
    <BCard class="border border-0 my-2 my-lg-0 recentWinnerCard">
        <h3 class="recentWinnerCard__heading">Recent Winners</h3>
        <div class="d-flex align-items-center my-4" v-for="(winner, index) in findRecipientsWithFallback" :key="index">
            <b-avatar class="carousel__item__img bg-white" v-b-avatar-img-fallback
                :src="winner?.personImg ? `${baseUrl}${winner?.personImg}` : profileAvatar"></b-avatar>
            <div class="d-flex flex-column mx-2">
                <span class="fw-bold recentWinnerCard__title">{{ winner.name }}</span>
                <span class="recentWinnerCard__title">{{ winner.awardName }}</span>
            </div>
        </div>
    </BCard>
</template>
<script>
    import "../assets/scss/index.scss";

    import profileAvatar from "@/assets/imgs/profile-avatar.png";

    export default {
        data() {
            return {
                profileAvatar,
            };
        },
        methods: {
            findRecipientsByYear(targetYear) {
                return (this.golfClubData?.awards || [])
                    .flatMap(award =>
                        award.persons.filter(person => {
                            const year = person.date?.split("/")[0];
                            return parseInt(year) === targetYear;
                        })
                            .map(person => ({
                                name: person.name,
                                year: person.date,
                                personImg: person.image,
                                awardName: award.name,
                            }))
                    );
            },
            getMinDate() {
                const awards = this?.golfClubData?.awards;
                if (!awards || awards.length === 0) {
                    return null;
                }
                let awardYears = awards
                    .flatMap(personsObj => personsObj.persons.map(data => Number(data.date))).filter(Boolean) || [];
                return Math.min(...awardYears);
            },
        },
        computed: {
            findRecipientsWithFallback() {
                const currentYear = new Date().getFullYear();
                const minDate = this.getMinDate() || currentYear;
                let recipients = [];

                //If we have enough recipients in the current year, show them.
                let thisYearsRecipients = this.findRecipientsByYear(currentYear);
                if (this.findRecipientsByYear(currentYear) > 6) {
                    return thisYearsRecipients;
                }

                //If we don't have enough recipients to fill the slider, go back through all the years.
                for (let targetYear = currentYear; targetYear >= minDate; targetYear--) {
                    let yearRecipients = this.findRecipientsByYear(targetYear);
                    recipients = recipients.concat(yearRecipients);
                }

                //Limit the output to a max of 50 items for performance reasons.
                return recipients.slice(0, 50);
            },
        },
    };
</script>
<style lang="scss"></style>
