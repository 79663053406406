<template>
    <div class="sponsor">
        <h2 class="text-center text-md-start mb-3">Sponsors</h2>
        <BRow class="">
            <BCol md="8" class="mx-0 px-2 sponsor__block">
                <BRow class="p-0 m-0">
                    <BCol cols="6" sm="12" md="12" lg="6" xl="4" class="p-1 p-lg-2" v-for="(sponsorCard, index) in this.golfClubData
                        ?.sponsors" :key="index" @click="handleCardSelection(sponsorCard)">
                        <SponsorsCard class="mx-0 mb-3" :image="`${this.baseUrl}${sponsorCard?.imageFile}`" :logo="`${this.baseUrl}${sponsorCard?.imageFile}`"
                            :headingText="sponsorCard?.name" />
                    </BCol>
                </BRow>
            </BCol>
            <BCol md="6" lg="4" class="mx-0 mt-1 d-none d-md-block" v-if="selectedCard !== null">
                <CommunityCard :data="selectedCard" class="fixed-comunity-card p-1" />
            </BCol>

            <b-modal v-model="openModal" size="lg" hide-header hide-footer class="winnerAward-modal d-md-none" :centered="true">
                <img src="../assets/svgs/close.svg" class="close__img" @click="openModal = false" />
                <BCard class="border border-0 comunityCard h-auto ">
                    <div>
                        <div>
                            <img class="mb-2 comunityCard__img" :src="`${this?.baseUrl}${selectedCard?.imageFile}`" alt="" />
                            <div class="d-flex align-items-center mb-2" v-if="$route.name == 'sponsors'">
                            </div>
                        </div>
                        <BCardTitle class="font-weight-bold comunityCard__title">{{ selectedCard?.name }}</BCardTitle>
                        <BCardText class="comunityCard__description">
                            {{ selectedCard?.extra }}
                        </BCardText>
                    </div>
                </BCard>
            </b-modal>
        </BRow>
    </div>
</template>

<script>
    import SponsorsCard from "@/components/SponsorsCards.vue";

    import CommunityCard from "@/components/CommunityCard.vue";

    export default {
        components: {
            SponsorsCard,
            CommunityCard,
        },
        data() {
            return {
                selectedCard: null,
                openModal: false,
            };
        },
        watch: {
            golfClubData: {
                handler(newData) {
                    if (
                        newData &&
                        newData.sponsors &&
                        newData.sponsors.length > 0
                    ) {
                        this.selectedCard = newData.sponsors[0];
                    } else {
                        this.selectedCard = null;
                    }
                },
            },
        },
        methods: {
            handleCardSelection(card) {
                this.selectedCard = card;
                this.openModal = true;
            },
        },

        mounted() {
            if (
                this.golfClubData &&
                this.golfClubData.sponsors &&
                this.golfClubData.sponsors.length > 0
            ) {
                this.selectedCard = this.golfClubData?.sponsors[0];
            }
        },
    };
</script>

<style lang="scss">
.fixed-comunity-card {
    position: fixed !important;
    width: 400px !important;

    @media screen and (min-width: 2560px) and (max-width: 5000px) {
        // aspect-ratio: 1;
        width: 25% !important;
        height: auto !important;
    }
}
</style>
 