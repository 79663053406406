<template>
    <div class="d-flex justify-content-between align-items-center">
        <BFormGroup class="my-2">
            <BFormCheckbox
                :modelValue="selectedCheckBox?.some((obj) => obj.start == name)"
                @update:modelValue="$emit('change', name)"
                size="md"
                :class="{
                    'selected-checkbox': selectedCheckBox?.some(
                        (obj) => obj.start == name
                    ),
                }"
            >
                <div v-if="selectedCheckBox.some((obj) => obj.start == name)">
                    <strong>{{ name }}</strong>
                    <strong v-if="end">{{ `-${end}` }}</strong>
                </div>
                <div v-else>
                    <span>{{ name }}</span>
                    <span v-if="end">{{ `-${end}` }}</span>
                </div>
            </BFormCheckbox>
        </BFormGroup>
        <div>
            <BBadge
                :class="
                    selectedCheckBox.some((obj) => obj.start == name)
                        ? 'bg-success badge'
                        : 'bg-white badge text-dark border border-1'
                "
                >{{ length }}</BBadge
            >
        </div>
    </div>
</template>

<script setup>
import { defineProps, ref, onMounted } from "vue";

const props = defineProps([
    "name",
    "title",
    "selectedCheckBox",
    "end",
    "length",
]);
console.log("id", props.id);
</script>

<style lang="scss">
.filterCard {
    border-radius: 16px !important;
    margin-bottom: 20px;
}

.form-check-input:checked {
    background-color: #5bc89c !important;
    color: white !important;
    border: none;
}

.selected-badge {
    background-color: #5bc89c !important;
    color: white;
    border: none;
}

.bg-success {
    background-color: #5bc89c !important;
    color: white;
}

.badge {
    border-radius: 20px !important;
}
</style>
